import Vue from 'vue'
import App from './App'
import router from './router'
import 'amfe-flexible'
import './styles/base.css'
import scroll from 'vue-seamless-scroll'
import baseActivityRules from '@/components/base-activity-rules/base-activity-rules.vue'
import { Toast, Area, Popup } from 'vant'
// 自定义指令
import bg from './plugins/bg'
// 导入注册埋点插件
import tracking from './plugins/tracking'

// fundebug-vue
/* import * as fundebug from "fundebug-javascript";
import FundebugVue from "fundebug-vue";
fundebug.init({
    apikey: "d07fc1798254382f0bc58f6b81863cdf8c3d879f7e91db8a323934d622a66961",
    releasestage: process.env.VUE_APP_edition,
})

console.log("版本", process.env.VUE_APP_edition);
new FundebugVue(fundebug).installVueErrorHandler(Vue); */

Vue.use(tracking, {
  router
})
Vue.use(bg)
Vue.use(Toast)
Vue.use(scroll)
Vue.use(Popup)
Vue.use(Area)
Vue.config.productionTip = false
Vue.component('baseActivityRules', baseActivityRules)

new Vue({
  el: '#app',
  // store,
  router,
  render: h => h(App)
}).$mount('#app')
